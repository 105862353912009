// export const base_url = `http://localhost:5001/`;
// export const base_url = `http://localhost:5001/`;



export const base_url = `${window.location.origin}/backend/`




// for image 

export const image_baseurl = `https://stockboxpnp.pnpuniverse.com/`;
